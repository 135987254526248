const _ = require('lodash');

const logisticFactors = {
    "germany": {
        "label": "Germany",
        "factor": 0.00004079
    },
    "china": {
        "label": "China",
        "factor": 0.000307
    },
    "usa_east": {
        "label": "USA Eastcoast",
        "factor": 0.000162
    },
    "usa_west": {
        "label": "USA - Westcoast",
        "factor": 0.000246
    },
    "america_middle": {
        "label": "Central America",
        "factor": 0.000218
    },
    "america_south": {
        "label": "South America",
        "factor": 0.000188263
    },
    "africa": {
        "label": "Africa",
        "factor": 0.000180925
    },
    "india": {
        "label": "India",
        "factor": 0.000216794
    },
    "asia": {
        "label": "Asia (outside China + India)",
        "factor": 0.00029818
    },
    "oceania": {
        "label": "Oceania",
        "factor": 0.000287433
    },
    "poland": {
        "label": "Poland",
        "factor": 0.000044142009212
    },
    "europe_east": {
        "label": "East Europe (outside Poland + Czech)",
        "factor": 0.000137593
    },
    "italy": {
        "label": "Italy",
        "factor": 0.000126444
    },
    "french": {
        "label": "France",
        "factor": 0.00011158
    },
    "netherlands": {
        "label": "Netherlands",
        "factor": 0.00006390
    },
    "europe_central": {
        "label": "Central and West Europe (outside Italy, Germany, and France)",
        "factor": 0.00012499
    }
}

export const getProductOptions = (products) => {
    return _.map(products, (product, key) => {
        return {
            "value": product.p_key,
            "label": product.p_label
        }
    });
};

export const getLogisticOptions = () => {
    return _.map(logisticFactors, (obj, key) => {
        return {
            "value": key,
            "label": obj.label
        }
    });
}
export const getLogisticLabel = (key) => _.chain(logisticFactors).get(key, { "label": '' }).get("label").value();

export const getComponentOptions = (products, product) => {
    if (_.isEmpty(product) || !_.has(products, product)) return [];

    const filteredComponents = _.filter(products[product]['p_components'], (component) => !component.c_is_fixed && _.isEmpty(component.c_dependencies))
    const fullArray = filteredComponents.map((component) => {
        const componentName = component.c_value;
        const componentLabel = component.c_label;
        const minValue = component.c_min_value;
        const maxValue = component.c_max_value;
        const isBinary = component.c_is_binary;
        const unitOptions = component.c_unit_options;

        return { componentName, componentLabel, minValue, maxValue, isBinary, unitOptions }
    });
    const multiOptions = _.filter(fullArray, c => !c.isBinary)
    const binaryOptions = _.filter(fullArray, c => c.isBinary)
    /* const largeMachines = ['RA164', 'RA145'] */
    /*    return largeMachines.includes(product) ? _.zip(multiOptions) :
           (_.zip(multiOptions, binaryOptions)); */
    return (_.zip(multiOptions, binaryOptions));
}

/**
 * This function validate the input for calculation. Keep in mind that the components for each product
 *  are dynamic hence require dynamic validation.
 */
export const validateInput = (products, product, componentObj, destination) => {
    let isValid = true;
    let errorMessages = [];
    if (_.isEmpty(product)) { isValid = false; errorMessages.push("Select product"); }
    else if (!_.has(products, product)) { isValid = false; errorMessages.push("Product " + product + " is invalid"); }
    if (!destination || _.isEmpty(destination)) { isValid = false; errorMessages.push("Select delivery destination"); }

    if (_.has(products, product)) {
        const variableComponents = _.filter(products[product]['p_components'], (component) => {
            return !component.c_is_fixed && _.isEmpty(component.c_dependencies)
        })

        const componentValidationResult = _.reduce(variableComponents, (acc, component) => {
            if (!_.has(componentObj, component.c_value)) {
                acc.isValid = false;
                acc.messages.push("select " + component.c_label);
            }

            return acc;
        }, { isValid: true, messages: [] });

        isValid = isValid && componentValidationResult.isValid;
        errorMessages = errorMessages.concat(componentValidationResult.messages);
    }

    return { isValid, errorMessages };
}
const getPressRaiseValue = (prObj) => {
    const pressRaiseKey = Object.keys(prObj).find(key => key.endsWith('ERHOHUNG_FIX'));
    return pressRaiseKey ? prObj[pressRaiseKey] : 0;
};


export const calculatePCF = (products, product, componentObj, destination, uncertainty) => {
    validateInput(products, product, componentObj, destination); // unnecessary, but useful for debugging.
    return _.reduce(products[product]['p_components'], (acc, component) => {
        let quantity = 0;

        // Below ugly logic just to get the quantity of the component.
        if (component.c_is_fixed) {
            quantity = 1;
        } else if (_.isEmpty(component.c_dependencies)) {
            quantity = (componentObj[component.c_value] + component.c_adjustment);
        } else {
            // this is the dependent components
            quantity = 0;
            //Additional condition for Press Raise :Only add variable dependencies if press raise is checked!
            if (_.toLower(component.c_label) === 'press raise variable' && getPressRaiseValue(componentObj) === 0) {
                quantity = 0;
            } else {
                component.c_dependencies.forEach((dep) => {
                    if (_.get(dep, 'negate', false)) {
                        if (componentObj[dep.key] === 0) {
                            quantity += 1;
                        }
                    } else {
                        quantity += (componentObj[dep.key] * _.get(dep, 'multiplier', 0))
                    }
                });
            }

        }

        const _purchasedGoodEmission = (component.c_emission_per_component * quantity);
        acc.purchasedGoodEmission += _purchasedGoodEmission;
        const _productionEmission = (component.c_production_emission * quantity);
        acc.productionEmission += _productionEmission;
        const _packagingEmission = (component.c_total_emission_packaging * quantity);
        acc.packagingEmission += _packagingEmission;
        const _outboundLogistic = (component.c_bruto_weight_kg * logisticFactors[destination].factor * quantity);
        acc.outboundLogistic += _outboundLogistic;
        const _inboundLogistic = (component.c_inbound_logistics * quantity);
        acc.inboundLogistic += _inboundLogistic;
        const _totalWeight = (component.c_bruto_weight_kg * quantity);
        acc.totalLogistic += _inboundLogistic + _outboundLogistic;
        acc.totalWeight += _totalWeight;
        acc.unspecified += ((_purchasedGoodEmission + _productionEmission + _inboundLogistic + _outboundLogistic + _packagingEmission) * uncertainty);

        // console.log(component.c_value, {
        //     quantity,
        //     totalWeight: _totalWeight,
        //     purchasedGoodEmission: _purchasedGoodEmission,
        //     productionEmission: _productionEmission,
        //     packagingEmission: _packagingEmission,
        //     outboundLogistic: _outboundLogistic,
        //     inboundLogistic: _inboundLogistic,
        // })

        return acc;
    }, {
        "purchasedGoodEmission": 0.0, // Material, Packaging, Inbound Logistics
        "outboundLogistic": 0.0,
        "inboundLogistic": 0.0,
        "unspecified": 0.0,
        "totalWeight": 0.0,
        "packagingEmission": 0.0,
        "productionEmission": 0.0,
        "totalLogistic": 0.0,
        "total": 0.0
    });
}

export const readProductFromCsvResult = (product, csvResultData) => {
    return {
        "p_key": product.key,
        "p_imageUrl": product.imageUrl,
        "p_label": product.label,
        "p_uncertainty": product.uncertainty,
        "p_image_component": product.imageComponent,
        "p_result_image_component": product.resultImageComponent,
        "p_components": _.chain(csvResultData)
            .drop()
            .map((component) => {
                return {
                    "c_value": component[0],
                    "c_label": component[1],
                    "c_netto_weight_kg": Number(component[2]),
                    "c_bruto_weight_kg": Number(component[3]),
                    "c_emission_per_component": Number(component[4]),
                    "c_unit_options": JSON.parse(component[5]),
                    "c_min_value": Number(component[6]),
                    "c_max_value": Number(component[7]),
                    "c_adjustment": Number(component[8]),
                    "c_is_fixed": component[9] === "TRUE",
                    "c_is_binary": component[10] === "TRUE",
                    "c_dependencies": JSON.parse(component[11]),
                    "c_production_emission": Number(component[12]),
                    "c_total_emission_packaging": Number(component[13]),
                    "c_inbound_logistics": Number(component[14]),
                }
            })
            // sort it so that the "number input" comes first and the binary appears last.
            .orderBy([(item) => {
                return _.get(item, 'c_unit_options', []).length === 0 && !_.get(item, 'c_is_binary', false)
            }, (item) => {
                return !_.get(item, 'c_is_binary', false)
            }], ['desc', 'desc'])
            .value()
    }
}

export default {
    readProductFromCsvResult,
    calculatePCF,
    validateInput,
    getProductOptions,
    getLogisticLabel,
    getLogisticOptions
}