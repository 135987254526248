

const formatNumber = (number, minimumFractionDigits = 0, maximumFractionDigits = 0, lng = 'en-US',) => {
  return number?.toLocaleString(lng, { minimumFractionDigits, maximumFractionDigits })
}


const numberFormatter = {
  formatNumber,
}

export default numberFormatter
