import { PDFDocument, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import numberFormatter from './numberFormat.service';

export const productType = {
  S: "SMALL",
  L: "LARGE"
}
const createPcfCertificate = async (selectedProduct) => {
  const smallMachine = (selectedProduct.type === productType.S);

  const companyName = 'Koenig & Bauer AG';

  const currentDate = new Date();

  const formPdfBytes = smallMachine ? await fetch(require('../../resources/PCF-Bericht Template_en_koenig.pdf')).then(res => res.arrayBuffer()) : await fetch(require('../../resources/PCF-Bericht Grossformat.pdf')).then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(formPdfBytes)

  // get the first (and only) page of the document
  const pages = pdfDoc.getPages()
  const firstPage = pages[0]

  // register barlow as embedded font, so we can use it later
  pdfDoc.registerFontkit(fontkit)
  const barlowRegularBytes = await fetch(require('../../resources/fonts/Barlow-Regular.ttf')).then(res => res.arrayBuffer())
  const barlowBoldBytes = await fetch(require('../../resources/fonts/Barlow-Bold.ttf')).then(res => res.arrayBuffer())

  const barlowRegular = await pdfDoc.embedFont(barlowRegularBytes)
  const barlowBold = await pdfDoc.embedFont(barlowBoldBytes)

  const { width } = firstPage.getSize()
  // 636.3  tnis is the base height of first para end 
  //499.5 this is the start height of second para first line *508

  // write company name on page
  const widthOfCompanyName = barlowRegular.widthOfTextAtSize(companyName, 18)
  firstPage.drawText(
    companyName,
    {
      x: width / 2 - widthOfCompanyName / 2,
      y: smallMachine ? 603.3 : 631.3,
      maxWidth: width - 140,
      size: 18,
      font: barlowRegular,
      color: rgb(0, 27 / 255, 89 / 255)
    }
  )
  // write product name on page
  // TODO: For long product names, this needs to have a line break and needs to be positioned differently
  const widthOfProductName = barlowBold.widthOfTextAtSize(selectedProduct.productName, 18)
  firstPage.drawText(
    selectedProduct.productName,
    {
      x: width / 2 - widthOfProductName / 2,
      y: smallMachine ? 581.8 : 609.8,
      maxWidth: width - 140,
      size: 18,
      font: barlowBold,
      color: rgb(0, 27 / 255, 89 / 255)
    }
  )

  // write PCF on page

  const pcf = numberFormatter.formatNumber(selectedProduct.totalPCF, 2, 2, 'en-US')
  const mainText = ' t CO';
  const subscriptText = "2"; // This will be our subscript
  const suffixText = "e"
  const pcfText = pcf + mainText;
  const subscriptOffset = 5;
  const subscriptSize = 18 * 0.6;
  const widthOfPcfText = barlowBold.widthOfTextAtSize(pcfText, 18);
  const subscriptTextWidth = barlowBold.widthOfTextAtSize(subscriptText, subscriptSize);
  const suffixTextWidth = barlowBold.widthOfTextAtSize(suffixText, 18);



  firstPage.drawText(
    pcfText,
    {
      x: width / 2 - (widthOfPcfText + subscriptTextWidth + suffixTextWidth) / 2,
      y: smallMachine ? 560.8 : 588.8,
      maxWidth: width - 140,
      size: 18,
      font: barlowBold,
      color: rgb(0, 27 / 255, 89 / 255)
    }
  )

  firstPage.drawText(subscriptText, {
    x: (width / 2 - (widthOfPcfText + subscriptTextWidth + suffixTextWidth) / 2) + widthOfPcfText,
    y: smallMachine ? (560.8 - subscriptOffset) : (588.8 - subscriptOffset),
    maxWidth: width - 140,
    size: subscriptSize,
    font: barlowBold,
    color: rgb(0, 27 / 255, 89 / 255)
  });

  // Draw suffix text
  firstPage.drawText(suffixText, {
    x: (width / 2 - (widthOfPcfText + subscriptTextWidth + suffixTextWidth) / 2) + widthOfPcfText + subscriptTextWidth,
    y: smallMachine ? 560.8 : 588.8,
    size: 18,
    font: barlowBold,
    color: rgb(0, 27 / 255, 89 / 255)
  });

  // write PCF per weight of product on page
  const pcfPerKg = numberFormatter.formatNumber(selectedProduct.pcfPerKg, 2, 2, 'en-US')
  const pcfPerKgText = `corresponds to ${pcfPerKg} t CO`
  const widthOfPcfPerKgText = barlowRegular.widthOfTextAtSize(pcfPerKgText, 12)
  const subscript_12TextWidth = barlowBold.widthOfTextAtSize(subscriptText, 12 * 0.6);
  const suffix12TextWidth = barlowBold.widthOfTextAtSize(`e / ton of product`, 12);


  firstPage.drawText(
    pcfPerKgText,
    {
      x: width / 2 - (widthOfPcfPerKgText + subscript_12TextWidth + suffix12TextWidth) / 2,
      y: smallMachine ? 531.3 : 559.3,
      maxWidth: width - 140,
      size: 12,
      font: barlowRegular,
      color: rgb(120 / 255, 120 / 255, 120 / 255)
    }
  )
  firstPage.drawText(
    subscriptText,
    {
      x: (width / 2 - (widthOfPcfPerKgText + subscript_12TextWidth + suffix12TextWidth) / 2) + widthOfPcfPerKgText,
      y: smallMachine ? (531.3 - subscriptOffset) : (559.3 - subscriptOffset),
      maxWidth: width - 140,
      size: 12 * 0.7,
      font: barlowRegular,
      color: rgb(120 / 255, 120 / 255, 120 / 255)
    }
  )
  firstPage.drawText(
    `e / ton of product`,
    {
      x: (width / 2 - (widthOfPcfPerKgText + subscript_12TextWidth + suffix12TextWidth) / 2) + widthOfPcfPerKgText + subscript_12TextWidth,
      y: smallMachine ? 531.3 : 559.3,
      maxWidth: width - 140,
      size: 12,
      font: barlowRegular,
      color: rgb(120 / 255, 120 / 255, 120 / 255)
    }
  )
  /* ****************** */

  /*  firstPage.drawText(
     product.type_of_inventory || '',
     {
       x: 205.5,
       y: 386.3,
       size: 12,
       font: barlowRegular
     }
   ) */
  /*  console.log('product from pdf', product); */
  /* need to check commas scenario */

  const scope_1 = `Direct emissions (Scope 1), `;
  const scope_2 = `Indirect emissions (Scope 2), `;
  const scope_3_1 = 'Purchased goods (Scope 3.1), ';
  const scope_3_5 = 'Waste (Scope 3.5) ';
  const scope_3_9 = 'and Downstream logistics (Scope 3.9).';

  const emmisions = `${scope_1}${scope_2}${scope_3_1}${scope_3_5}${scope_3_9}`;
  if (smallMachine) {
    firstPage.drawText(
      emmisions,
      {
        x: 205.5,
        y: 357.4,
        maxWidth: (width - 303),
        size: 12,
        lineHeight: 14.5,
        font: barlowRegular
      }
    )
  }

  firstPage.drawText(
    currentDate.toLocaleDateString('en-US'),
    {
      x: 205.5,
      y: smallMachine ? (285.5) : 343,
      size: 12,
      font: barlowRegular
    }
  )


  /*   firstPage.drawText(
      `${product.company_legal_name}\n${product?.company_address_street || ''}\n${product?.company_address_city || ''}\n${product?.company_website || ''}`,
      {
        x: 205.5,
        y: 256.8,
        size: 12,
        lineHeight: 14.5,
        font: barlowRegular
      }
    ) */

  // set pdf properties
  pdfDoc.setTitle(`PCF Report ${selectedProduct.productName}`)
  pdfDoc.setAuthor('Telusio GmbH')
  pdfDoc.setSubject(`PCF report of the product ${selectedProduct.productName} of the company ${companyName}`)
  pdfDoc.setProducer('Produced by Telusio with \'pdf-lib\'')
  pdfDoc.setCreationDate(currentDate)

  // save the updated pdf to a new array buffer
  const newPdfBytes = await pdfDoc.save()
  const blob = new Blob([newPdfBytes], { type: 'application/pdf;charset=utf-8' })

  // implement a hidden link that directly downloads the pdf file
  const link = document.createElement('a')
  link.setAttribute('href', URL.createObjectURL(blob))
  link.setAttribute('download', `PCF Report ${selectedProduct.productName}-${currentDate.toLocaleDateString('en-US').replace(/\./g, '-')}`)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const PdfService = {
  createPcfCertificate
}

export default PdfService
