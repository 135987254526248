import React, { useState } from 'react'
import styles from '../resources/css/totalPCFCard.module.css';
import _ from 'lodash';
import moment from 'moment';
import { AutoComplete, Button, Col, Form, Input, Row, Spin, notification } from "antd";
import config from "../config";
import { useAuth } from "../hooks/AuthProvider";

const ExtraInfoCard = ({
    totalPCF,
    totalWeight
}) => {
    const user = useAuth();
    const [contactForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const handleComponentChange = (componentKey) => (e) => {
        contactForm.setFieldValue({
            [componentKey]: e.target.value
        });
    }

    const handleAutoCompleteChangeChange = (componentKey) => (value) => {
        contactForm.setFieldValue({
            [componentKey]: value
        });
    }

    const handleSubmitContact = async () => {
        const formBody = contactForm.getFieldsValue(['salutation', 'firstName', 'lastName', 'email', 'phoneNumber', 'institution'])
        setLoading(true)
        await contactForm.validateFields()
            .then(async () => {
                const response = await fetch(`${config.backendBaseUrl}/lead-contact`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer ' + user.token
                    },
                    body: JSON.stringify(formBody),
                });

                if (!response.ok) {
                    const res = await response.json();
                    throw new Error(res.message)
                }

                api.info({
                    message: `Your information has been safely stored. We will contact you soon \uD83D\uDE0A`,
                    placement: 'topRight'
                })

                contactForm.resetFields();
            })
            .catch((err) => {
                if (!err.errorFields || _.isEmpty(err.errorFields)) {
                    api.error({
                        message: `We couldn't save your info: ${err}`,
                        placement: 'topRight'
                    })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const salutationOptions = [
        { value: 'Mr.' },
        { value: 'Mrs.' },
        { value: 'Ms.' },
        { value: 'Miss' },
        { value: 'Dr.' },
        { value: 'Rev.' },
        { value: 'Prof.' },
        { value: 'Capt.' },
        { value: 'Sgt.' },
        { value: 'Lord' },
        { value: 'Lady' },
        { value: 'Hon.' },
        { value: 'Pres.' }
    ]
    return (
        <div className={styles.transparentCard}>
            {contextHolder}
            <div className={styles.titleText}>
                <h3>Further Information</h3>
            </div>

            <div>
                <ul>
                    <li>Overall weight of the printing press {totalWeight.toFixed(2)} to</li>
                    <li>{(totalPCF / totalWeight).toFixed(2)} t CO<sub>2</sub>e per ton of the printing press</li>
                    <li>Type of inventory: Cradle-to-customer</li>
                    <li>Calculated on {moment(new Date()).format("MMMM Do YYYY")}</li>
                    <li>Verified PCF calculation is based on GHG protocol and ISO 14067</li>
                </ul>
                {/* COMMENTED FOR VERSION 2 WIHOUT 'GET IN TOUCH' FEATURE */}
                {/* 
                <div className={styles.titleText} style={{ marginTop: '3em' }}>
                    <Form form={contactForm}>
                        <h3>Want to know more? Let us help you!</h3>

                        <Row gutter={16} key={"contact-r1"} style={{paddingLeft: '1em'}}>
                            <Col className="gutter-row" span={4}>
                                <span style={{fontWeight: 'bold'}}>{'Your name'}</span>
                            </Col>
                            <Col className="gutter-row" span={4}>
                                <Form.Item
                                    name={'salutation'}
                                    key={"form-item-salutation"}
                                    initialValue={_.get(contactForm, 'salutation', '')}
                                >
                                    <AutoComplete
                                        options={salutationOptions}
                                        placeholder="Salutation"
                                        filterOption={(inputValue, option) => {
                                            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }}
                                        onChange={handleAutoCompleteChangeChange('salutation')}
                                    />
                                </Form.Item>

                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item
                                    name={'firstName'}
                                    key={"form-item-firstname"}
                                    initialValue={_.get(contactForm, 'firstName', '')}
                                    rules={[{ required: true, message: 'required' }]}
                                >
                                    <Input
                                        placeholder={'First Name'}
                                        onChange={handleComponentChange('firstName')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item
                                    name={'lastName'}
                                    key={"form-item-lastname"}
                                    initialValue={_.get(contactForm, 'lastName', '')}
                                    rules={[{ required: true, message: 'required' }]}
                                >
                                    <Input
                                        placeholder={'Last Name'}
                                        onChange={handleComponentChange('lastName')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} key={"contact-r2"} style={{paddingLeft: '1em'}}>
                            <Col className="gutter-row" span={4}>
                                <span style={{fontWeight: 'bold'}}>{'Where can we reach you?'}</span>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item name={'email'} key={"form-item-email"} rules={[{ required: true, message: 'We need your email to reach you' }]}>
                                    <Input
                                        placeholder={'email'}
                                        onChange={handleComponentChange('email')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item name={'phoneNumber'} key={"form-item-phone"}>
                                    <Input
                                        placeholder={'+498xxxxx'}
                                        onChange={handleComponentChange('phoneNumber')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} key={"contact-r3"} style={{paddingLeft: '1em'}}>
                            <Col className="gutter-row" span={4}>
                                <span style={{fontWeight: 'bold'}}>{'Where do you work?'}</span>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item name={'institution'} key={"form-item-institution"}>
                                    <Input
                                        placeholder={'institution'}
                                        onChange={handleComponentChange('institution')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} key={"contact-r4"} style={{paddingLeft: '1em'}}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item key={"form-item-submit"}>
                                    <Button htmlType="submit" onClick={handleSubmitContact}>
                                        Get in Touch!
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div> */}
            </div>
            <Spin spinning={isLoading} fullscreen />
        </div>
    )
}

export default ExtraInfoCard;