import React from 'react'
import { Button, Col, Row, Space, Typography } from 'antd'

import TBreadCrumbs from './TBreadCrumbs'
import knbLogo from '../../resources/Koenig_Bauer_blau_auf_weiss.png'

const { Title } = Typography

const TSiteHeader = ({ breadCrumbItems = [], pageTitle, pageDescription, ctas = [] }) => {
  return (
    <>
      <Row gutter={16}>
        <Col className="gutter-row" span={16}>
          <Title style={{ margin: '24px 0' }}>{pageTitle}</Title>
        </Col>
        <Col className="gutter-row" span={8} style={{display: "flex", justifyContent: "flex-end"}}>
          <img src={knbLogo} alt='Koenig & Bauer Logo' style={{ height: 110, margin: '0 0' }} />
        </Col>
      </Row>
    </>
  )
}

export default TSiteHeader
